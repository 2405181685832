import { useEffect } from "react";

const AdSlot728x90 = ({ divId = "", minHeight = "", minWidth = "" }) => {
  useEffect(() => {
    //* call display ads after 1 second
    setTimeout(() => {
      if (!window.googletag) {
        return;
      }
      if (window.screen.width > 900) {
        googletag.cmd.push(function () {
          // console.log("calling display ads", divId);
          googletag.display(divId);
        });
      }
    }, 600);

    setTimeout(function () {
      if (
        document.querySelectorAll('[id^="google_ads_iframe_"]')?.length === 0
      ) {
        const googletag = window.googletag || {};
        if (typeof googletag.pubads === "function") {
          console.log("Refresh ads fired");
          //googletag.pubads().refresh();
        }
      }
    }, 1000);
  }, []);

  return (
    <div
      className=" bg-gray-100 rounded-sm text-center my-1 hidden md:block lg:block xl:block"
      style={{
        overflow: "auto",
        height: "130px",
      }}
    >
      <p className="text-xs font-extralight text-slate-600 py-1">
        ADVERTISEMENT
      </p>
      <div className="flex justify-center items-center">
        <div
          id={divId}
          style={{
            width: minWidth,
            height: minHeight,
          }}
        />
      </div>
    </div>
  );
};

export default AdSlot728x90;
